* {
  box-sizing: border-box;
}

/* signin page */

.axil-signin-area {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.signin-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 40px 100px;
  z-index: 1;
}
body {
  overflow: visible;
  overflow-y: visible;
}

.accordion-button {
  position: relative;
  /* display: flex; */
  align-items: center;
  width: 100%;
  /* padding: 1rem 1.25rem; */
  font-size: 16px;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.accordion-item {
  background-color: #fff;
  border: 0px;
}

/* button.btn.btn-primary.dd {
    width: 10%;
}

button.btn.btn-primary.dds {
    width: 10%;
} */

.etrade-newsletter-wrapper.bg_image.bg_image--5 {
  margin-top: 10px !important;
}
h2.accordion-header {
  margin-top: 10px !important;
}

.thumbnail {
  margin-top: 10px !important;
}
.left img {
  width: 70%;
  margin: auto;
  display: flex;
  margin-top: 4px;
}

.Right img {
  width: 30%;
}

.left {
  width: 52%;
  float: left;
  background-color: #a70a21;
  /* height: 199px; */
}

.Right {
  /* width: 50%; */
  background-color: #222222;
  height: 599px !important;
}

.pro {
  width: 100%;
  float: left;
}
h3.left1 {
  text-align: center;
  font-size: 36px;
  color: #ffff;
}

p.right1 {
  text-align: center;
  color: #fff;
  margin-bottom: 15px;
}

h2.right2 {
  /* width: 50%; */
  color: #ffff;
  margin: 0px 11px 6px 6px;
}

h2.right2 {
  padding: 50px 0px 10px 733px !important;
  font-size: 35px;
  padding-top: 51px;
}

p.right2 {
  color: #fff;
  margin-left: 739px !important;
  justify-content: center;
}

.Right img {
  margin: auto;
  display: flex;
}

.service-area {
  width: 100%;
  float: left;
  margin-top: 23px;
}
.new {
  width: 100%;
  float: left;
  background-color: #e51b25;
}

h2.mm {
  font-size: 30px;
  margin-top: 100px;
  color: #ffff;
  font-weight: 700;
}

p.kk {
  color: white;
}
footer.axil-footer-area.footer-style-2 {
  margin-top: 15px !important;
  width: 100%;
  float: left;
}
@media screen and (max-width: 680px) {
  .left {
    width: 100% !important;
  }
}
@media screen and (max-width: 680px) {
  .Right {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .thumbnaiddl {
    margin-left: 0px !important;
  }
}
@media only screen and (max-width: 600px) {
  .dd {
    width: 31% !important;
  }
}
@media only screen and (max-width: 600px) {
  .dds {
    width: 31%;
  }
}
@media only screen and (max-width: 600px) {
  .mmmm {
    margin-top: 10px !important;
  }
}
@media only screen and (max-width: 600px) {
  button.btn.dd {
    width: 31%;
  }
}
@media only screen and (max-width: 600px) {
  button.btn.dds {
    width: 31%;
  }
}

.header-top-campaign p {
  text-align: center;
  color: white;
}

.header-top-campaign p {
  font-weight: 700;
}

.header-top-campaign a {
  text-decoration: none;
}
.axil-signin-form-wrap {
  width: auto;
}

a.axil-btn.btn-bg-secondary.sign-up-btn {
  text-decoration: none;
}

.header-top-campaign p {
  text-align: center;
  color: white;
  font-weight: 700;
}

.header-top-campaign a {
  text-decoration: none;
}
span.address.mb--20 {
  font-size: 17px;
  color: #777777;
  font-family: sans-serif;
}
.fg {
  margin-left: 28px !important;
}
.campaign-content a {
  text-decoration: none !important;
}
.fff {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  width: 80% !important;
}
.rr {
  margin-top: 30px;
}
h2.mid {
  text-align: center;
  font-family: var(--font-secondary) !important;
}
button.shopmenu {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.accordion-body {
  padding: 0rem 0rem !important;
}
.axil-shop-sidebar .product-categories ul {
  margin: 5px 0 !important;
}
.axil-shop-sidebar .product-categories ul li {
  padding: 4px 0 !important;
}
.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.834)
    url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
    no-repeat;
  z-index: 1;
}

.loader-containers {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(0, 0, 0, 0.6); */
  /* position: fixed; */
  /* z-index: 1; */
}

.product-blank {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 150px 0px !important;
  color: #000 !important;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #000 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
button.shopmenu {
  text-align: start;
  padding-left: 13px !important;
}
a#backto-top {
  background: #e01a1a !important;
}

.header-action {
  margin-top: 7px !important;
}

.hero {
  padding-top: 0px !important;
}
button.verify-btn.axil-btn.btn-bg-secondary {
  margin-top: 6px !important;
}
button.verify-btn.axil-btn.btn-bg-secondary {
}

li.shopping-cart.fg {
  margin-top: 18px !important;
}
p.paragraph-3 {
  color: white !important;
}
.delivered__container h1 {
  color: white !important;
}
.second__subscription__heading__wrapper h1 {
  color: white !important;
}

.dd {
  /* width: 15 !important; */
  padding-top: 10px !important;
  font-size: 20px !important;
  padding-bottom: 10px;
  width: auto !important;
  background-color: #e01a1a !important;
  border: none !important;
}

.dd:hover {
  background-color: #e01a1a !important;
}

button.btn.btn-primary.dds {
  padding-top: 10px !important;
  font-size: 20px !important;
  padding-bottom: 10px;
  width: auto !important;
  background-color: #e01a1a !important;
  border: none !important;
}
.neww {
  color: #e01a1a !important;
}

i.fas.fa-envelope-open {
  background-color: #e01a1a !important;
}

li.axil-breadcrumb-item.active {
  color: #e01a1a !important;
}
.ffff {
  color: white !important;
}
button.logg:before {
  background-color: #edff00 !important;
}

.logg {
  color: #e01a1a !important;
}

button.logg {
  margin-bottom: 10px !important;
}
span.error-display {
  color: #e01a1a !important;
}
p.sorry {
  color: #e01a1a !important;
}
a.axil-btn.btn-bg-primary.checkout-btn {
  background-color: red !important;
}
td.order-total-amount {
  color: #e01a1a !important;
}

ul.action-list {
  float: right !important;
}
.ffsd {
  margin-bottom: 27px !important;
  margin-top: 20px !important;
}
h2.accordion-header {
  border: 1px solid #00000052;
}

.accordion-body p {
  font-size: 15px;
  margin-top: 10px;
}
.etrade-newsletter-wrapper.bg_image.bg_image--5 {
  width: 100% !important;
  float: left !important;
}
.etrade-newsletter-wrapper.bg_image.bg_image--5 {
  width: 100% !important;
  float: left !important;
}

button.dd {
  margin-bottom: 10px;
}

button.dds {
  margin-bottom: 10px !important;
}
.accordion-item {
  border: none !important;
}

h2.acco.accordion-header {
  font-size: 20px !important;
  font-size: 10px !important;
}

button.accordion-button {
  font-size: 18px !important;
}
button.lo {
  float: left !important ;
  margin-left: 10px !important;
}
.btn {
  border: none;
  outline: none;
  padding: 10px 16px;
  background-color: #f1f1f1;
  cursor: pointer;
  font-size: 18px;
}
.btn:hover {
  background-color: red !important;
  color: white !important;
}
button.btn {
  text-align: start;
  padding-left: 13px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background: var(--color-lighter);
  border: 1px solid var(--color-border);
  transition: all 0.4s ease-out 0s;
  color: var(--color-heading);
  width: 100%;
  font-size: 14px;
  font-family: sans-serif;
  font-weight: 600;
}
button.dd {
  color: white !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

button.btn.btn-primary.dds {
  color: white;
}
.thumbnaiddl {
  width: 65% !important;
  margin-left: 77px;
}

.axil-section-gap {
  padding: 10px !important;
}
button.slick-arrow.slick-next {
  color: black !important;

  margin-right: 48px;
}
button.slick-arrow.slick-prev {
  color: black !important;

  /* margin-right: 48px; */
}
button.btn.dd {
  /* width: 15% !important; */
  text-align: center !important;
  padding-left: 0px !important;
}
button.vieww {
  text-align: center !important;
}
.rotate__images__wrapper__3 {
  animation: rotate__images__wrapper__3 7s infinite;
}

@keyframes rotate__images__wrapper__3 {
  40% {
    transform: rotate(30deg);
  }
}
.rotate__images__wrapper__2 {
  animation: rotate__images__wrapper__2 7s infinite;
}

@keyframes rotate__images__wrapper__2 {
  40% {
    transform: rotate(30deg);
  }
}
.rotate__images__wrapper__1 {
  animation: rotate__images__wrapper__1 7s infinite;
}

@keyframes rotate__images__wrapper__1 {
  40% {
    transform: rotate(30deg);
  }
}
.fdf {
  margin-top: 10px !important;
}

.campaign-content p {
  margin-left: -60px !important;
}
th.colld {
  float: left !important;
  padding: 0 px !important;
  padding: 0px !important;
  padding-left: 14px !important;
  padding-top: 21px !important;
  /* width: 100%; */
}

td.name {
  float: left !important;
  padding: 0px !important;
  text-align: start;
  margin-left: 11px;
}

th.stt {
  float: left;
  margin-left: 70px !important;
}

th.serw {
  float: left;
  margin-left: 37px;
}

button.btn.btn-secondary {
  float: left;
  text-align: center;
}
i.flaticon-shopping-cart {
  color: #2f2930;
  font-weight: 900 !important;
}
button.dds {
  text-align: center !important;
}
button.btn.btn-primary.dd {
  padding-left: 10px !important;
}

button.btn.btn-primary.dds {
  padding-right: 10px !important;
}
h2.mid {
  margin-top: 150px;
}
button.productt {
  width: auto !important;
  text-align: center !important;
  margin: auto !important;
  display: flex !important;
}
.colorr:active {
  background-color: #e01a1a !important;
}
.colorr:focus {
  background-color: #e01a1a !important;
  color: #fff;
}

.hhh {
  margin-top: 10px !important;
}

.fdf {
  margin-top: 18px !important;
  margin-bottom: 10px !important;
}
.axil-checkout-coupon {
  margin-top: 4px !important;
}

th.stt {
  margin-left: 83px !important;
}

td.fill {
  width: 100%;
  /* float: left; */
  /* margin-left: 12px; */
}
button.slick-arrow.slick-prev {
  display: none !important;
}

button.slick-arrow.slick-next {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  th.stt {
    float: none;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 600px) {
  th.serw {
    float: none;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .axil-dashboard-order .table tbody tr td {
    min-width: 0% !important;
  }
}
.btn-primary:active {
  background-color: #e01a1a !important;
}

@media only screen and (max-width: 600px) {
  th.colld {
    text-align: -webkit-auto;
  }
}

@media only screen and (max-width: 600px) {
  .name {
    text-align: -webkit-auto !important;
    float: left !important;
    width: 100% !important;
    /* text-align: center !important; */
  }
}
h1#staticBackdropLabel\ orderr {
  float: left !important;
  font-size: 20px !important;
  margin-left: 12px;
}

td.status {
  padding: 0px 61px 0px 0px !important;
}

td.fill {
  padding: 0px 56px 0px 0px !important;
}

section {
  overflow: hidden;
  /* specific image dimension, adjust as desired */
}

img {
  transition: filter 0.7s ease-in-out;
}

img[placeholder] {
  filter: blur(50px);
}

/* if there's no javascript executed we don't want to blur the image */
img[no-js] {
  filter: blur(0px);
}

.facts__content {
  position: relative;
  z-index: 2;
  width: 330px !important;
}
.flavor__background {
  background-position: 50% 50% !important ;
}